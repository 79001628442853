import config from "../config";
import { Amplify, Auth } from "aws-amplify"; 

Amplify.configure({
  Auth: {
    region: "us-west-1",
    userPoolId: "us-west-1_QHXjNDSxD",
    userPoolWebClientId: "1580es2d82v4h6tbue91gh006a"
  },
});

async function isTokenExpired() {
  try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      localStorage.setItem('token', idToken);
      const payload = JSON.parse(atob(idToken.split('.')[1]));
      
      const currentUnixTime = Math.floor(new Date().getTime() / 1000); // in seconds
      return currentUnixTime >= payload.exp;

  } catch (error) {
      console.error("Error checking token expiration:", error);
      return true; // If there's an error (e.g., no current session), assume token is expired
  }
}

const authProvider = {
  login: async ({ username, password }) => {
    const user = await Auth.signIn(username, password);
    const token = user.signInUserSession.idToken.jwtToken;

    localStorage.setItem("token", token);
    return Promise.resolve(); 
  } ,
  // Not in use currently
  verify: async ({ code }) => {
    const request = new Request(`${config.API_URL}/auth/verify`, {
      method: "POST",
      body: JSON.stringify({
        code: code,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      credentials: "include",
    });

    const response = await fetch(request);
    const token = response.headers.get("x-auth-token");

    if (token) {
      localStorage.setItem("token", token);
    } else {
      throw new Error(response.statusText);
    }

    return response.json();
  },
  logout: () => {
    localStorage.removeItem("token"); 
    return Auth.signOut()
  },
  checkAuth: async () => {
    return !(await isTokenExpired()) && localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  checkError: (error) => {
    if(error.status === 401){
      return Promise.reject(); //If it returns a rejected promise, react-admin calls the authProvider.logout() method immediately, and asks the user to log in again.
    }
    return Promise.resolve();
  },
  getPermissions: (params) => Promise.resolve(),
};

export default authProvider;
